/* --------------------------------------------------
下層ページ 弊社について /company/ 追加スタイル
-------------------------------------------------- */
.company {
}
.top {
    .greeting {
        position: relative;
        @include clearfix;
        &__photo {
            margin: auto;
            float: right;
            @include media(sp) {
                float: none;
                text-align: center;
            }
            img {
                @include media(pc) {
                    transform: translateX(30px);
                    margin-bottom: 0;
                }
                @include media(sp) {
                    width: 125px;
                }
            }
        }
        &__name {
            font-size: 1.1em;
            font-family: $font_mincho;
            text-align: right;
            letter-spacing: 0.1em;
            @include media(pc) {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            @include media(sp) {
                text-align: center;
            }
        }
    }
}
.philosophy {
    .header {
        text-align: center;
        margin-bottom: 40px;
        &__theme {
            margin-bottom: 40px;
            @include media(sp) {
                max-width: 60vw;
                display: block;
                margin: 0 auto 20px;
            }
        }
        &__copy {
            font-size: 33px;
            color: $site_color;
            font-family: $font_mincho;
            margin-bottom: 10px;
            letter-spacing: 0.15em;
            @include media(sp) {
                font-size: 20px;
            }
        }
        &__img {
            mix-blend-mode: multiply;
            max-width: 940px;
        }
    }
    .definition {
        max-width: 750px;
        margin: 0 auto 110px;
        @include media(sp) {
            margin-bottom: 55px;
        }
        &__copy {
            font-size: 21px;
            text-align: center;
            margin-bottom: 50px;
            letter-spacing: 0.2em;
            @include media(sp) {
                font-size: 18px;
            }
        }
        &__ttl {
            color: $site_color;
            font-size: 26px;
            text-align: center;
            letter-spacing: 0.2em;
            margin-bottom: 15px;
            @include media(sp) {
                font-size: 22px;
            }
        }
        &__img {
            @include margin-bottom(50px);
        }
    }
    .slogan {
        background: url(/assets/img/company/philosophy/slogan_bg.svg) center top / contain no-repeat;
        position: relative;
        max-width: 788px;
        margin: 0 auto 120px;
        font-family: $font_mincho;
        @include media(sp) {
            margin-bottom: 60px;
            background: none;
        }
        &:before {
            content: "";
            display: block;
            padding-top: 90.7%;
            @include media(sp) {
                width: 100%;
                padding-top: 14.9%;
                background: url(/assets/img/company/philosophy/slogan_bg_sp_top.svg) center top / contain no-repeat;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &:after {
            @include media(sp) {
                content: "";
                display: block;
                width: 100%;
                padding-top: 14.9%;
                background: url(/assets/img/company/philosophy/slogan_bg_sp_btm.svg) center top / contain no-repeat;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        &__inner {
            max-width: 565px;
            width: 72%;
            @include media(pc) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            @include media(sp) {
                width: 100%;
                padding: 8vw 8vw 10vw 5vw;
                &:before {
                    content: "";
                    display: block;
                    width: calc(100vw - 40px);
                    height: calc(100% - 25vw);
                    background: url(/assets/img/company/philosophy/slogan_bg_sp_mdl.svg) center top / contain repeat-y;
                    position: absolute;
                    left: 0;
                    top: 12vw;
                }
            }
            @include media(sp_small) {
                &:before {
                    width: calc(100vw - 32px);
                }
            }
        }
        &__ttl {
            font-size: 45px;
            letter-spacing: 0.2em;
            line-height: 1;
            margin-bottom: 15px;
            @include media(sp) {
                font-size: 24px;
            }
        }
        &__txt {
            @include margin-bottom(30px);
            @include media(sp) {
                font-size: 14px;
            }
        }
    }
}
.history {
    position: relative;
    #pin {
    }
    .timeline {
        background: #fff;
        padding: 60px;
        @include media(sp) {
            padding: 20px;
        }
        &__dot {
            width: 22px;
            height: 22px;
            margin-top: 7px;
            background: #fff;
            border: 2px solid $site_color;
            border-radius: 30px;
            position: absolute;
            top: 0;
            left: -12px;
            transition: 0.5s cubic-bezier(0.42, 0, 0.04, 0.99);
            z-index: 1;
            @include media(sp) {
                margin-top: 6px;
            }
        }
        &__list {
            padding: 5px 0 40px 80px;
            border-left: 2px solid $site_color;
            position: relative;
            @include media(sp) {
                padding: 5px 0 40px 40px;
            }
            @media screen and (min-height: 1140px) {
                padding-bottom: 25vh;
            }
            &:after {
                content: "";
                display: block;
                width: 30px;
                height: 16px;
                background: url(/assets/img/icon_arrow03.svg) top center / contain no-repeat;
                position: absolute;
                left: 0;
                bottom: -1px;
                transform: translateX(-50%);
                @include media(sp) {
                    width: 22px;
                    height: 11px;
                }
            }
        }
        &__item {
            margin-bottom: 60px;
        }
        &__ttl {
            background: $site_color;
            color: #fff;
            display: table;
            margin-bottom: 10px;
            position: relative;
            letter-spacing: 0.1em;
            padding: 3px 12px 5px 12px;
            line-height: 1;
            @include media(sp) {
                margin-bottom: 5px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 80px;
                height: 1px;
                background: $site_color;
                top: 13px;
                left: -80px;
                @include media(sp) {
                    width: 40px;
                    left: -40px;
                    top: 11px;
                }
            }
            &:after {
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                border-top: 1px solid $site_color;
                border-right: 1px solid $site_color;
                position: absolute;
                left: -9px;
                top: 14px;
                transform: translateY(-50%) rotate(45deg);
                @include media(sp) {
                    top: 12px;
                }
            }
        }
        &__txt {
            & + .timeline__ttl {
                margin-top: 60px;
                @include media(sp) {
                    margin-top: 40px;
                }
            }
        }
    }
}

.culture {
    .lead {
        &__txt {
            text-align: center;
            font-size: 22px;
            letter-spacing: 0.1em;
            @include margin-bottom(30px);
            @include media(sp) {
                font-size: 20px;
            }
        }
        .indent {
            @include fz_s;
        }
    }
    .box08 {
        p {
            @include fz_s;
        }
    }
}
