/* --------------------------------------------------
下層ページ トピックス /reading/ 追加スタイル
-------------------------------------------------- */
.reading {
    .topics {
        background: #fff;
        padding: 40px 70px 50px;
        margin-bottom: 50px;
        @media #{$sp} {
            padding: 20px 10px;
        }
        .ttl06 {
            text-align: left;
            margin-bottom: 10px;
            @media #{$sp} {
                font-size: 3rem;
                margin-bottom: 0;
            }
        }
    }
}

