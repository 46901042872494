@charset "utf-8";

// ===================================================================
// @mixin & @extend　定義
// ===================================================================

//フォントサイズ用ミックスイン
@mixin fz($size:12px, $important:'') {
    font-size: $size + px + $important;
    font-size: calc($size / 10) + rem + $important;
}
//vwからフォントサイズを算出
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

// margin & padding SPで数値を半分にする
@mixin margin($size1, $size2){
    margin: $size1 $size2;
    @include media(sp) {
        margin: math.div($size1, 2) math.div($size2, 2);
    }
}
@mixin margin-top($size){
    margin-top: $size;
    @include media(sp) {
        margin-top: math.div($size, 2);
    }
}
@mixin margin-bottom($size){
    margin-bottom: $size;
    @include media(sp) {
        margin-bottom: math.div($size, 2);
    }
}
@mixin padding($size){
    padding: $size;
    @include media(sp) {
        padding: math.div($size, 2);
    }
}
@mixin fz_s(){
    font-size: 16px;
    @include media(sp) {
        font-size: 14px;
    }
}

@mixin grd01 {
    background: linear-gradient(30deg, #2300a5 0%, #00004d 100%);
}

@mixin shadow01 {
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.3);
}

@mixin shadowBox01 {
    background: #fff;
    @include shadow01;
}

@mixin hoverEffect01{
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
}

@mixin objectFitBox($padding-top, $size:cover){//画像の縦横比保持&object-fit
    position: relative;
    &:before{
        content: '';
        display: block;
        width: 100%;
        padding-top: $padding-top;
    }
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        font-family: 'object-fit: $size;';
        object-fit: $size;
        top: 0;
        left: 0;
    }
}

@mixin objectFitImg($size:cover){//画像のobject-fit
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        font-family: 'object-fit: $size;';
        object-fit: $size;
        top: 0;
        left: 0;
    }
}

@mixin contentInner(){
    max-width: 960px + 32px * 2; //コンテンツ960px、計1024px
    margin: auto;
    padding: 0 32px;
    @media #{$pc_small}{
    }
    @media #{$tb}{
        // padding: 0 60px;
    }
    @include media(sp) {
        padding: 0 20px;
    }
    @media #{$sp_small}{
        padding: 0 5vw;
    }
}

@mixin top_fadeIn_anime($delay: 0s){
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s cubic-bezier(0.19, 0.47, 0.45, 0.94) $delay,
                transform 0.8s cubic-bezier(0.19, 0.47, 0.45, 0.94) $delay;
    @include media(sp) {
        transform: translateY(30px);
    }
}
@mixin fadeIn_anime($delay: 0s){
    opacity: 0;
    transform: translateY(0);
    transition: opacity 0.8s cubic-bezier(0.19, 0.47, 0.45, 0.94) $delay;
    &.is-inview{
        opacity: 1;
    }
}
@mixin fadeInUp_anime($delay: 0s){
    opacity: 0;
    transform: translateY(80px);
    transition: all 1s cubic-bezier(0.19, 0.47, 0.45, 0.94) $delay;
    @include media(sp) {
        transform: translateY(40px);
    }
    &.is-inview{
        opacity: 1;
        transform: translateY(0);
    }
}
@mixin isShow_anime($delay: 0s){
    opacity: 0;
    transform: translateY(50px);
    .is-show &{
        animation: fadeInUp_anime 1s cubic-bezier(0.19, 0.47, 0.45, 0.94) $delay both;
    }
}
@keyframes fadeInUp_anime{
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

//placeholderスタイル変更用
@mixin placeholder {
    &:placeholder-shown {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        opacity: 1;
        @content;
    }
    &::-moz-placeholder {
        opacity: 1;
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

// clearfixミックスイン
@mixin clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

