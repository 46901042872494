.l-footer {
    border-top: 1px solid #666;
    margin-top: 30px;
    padding: 50px 5.8% 25px;
    position: relative;
    @include media(sp) {
        margin-top: 86px;
        padding: 30px 20px;
    }
    .home & {
        @include media(pc) {
            margin-top: 110px;
        }
    }
    &__inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(sp) {
            display: block;
        }
    }
    &__nav {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 35px;
        margin-bottom: 30px;
        font-family: $font_mincho;
        @include media(sp) {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 30px 4%;
        }
        a {
            &[href="#"] {
                pointer-events: none;
            }
        }
    }
    &__item {
        min-width: 100px;
        @include media(sp) {
            width: 48%;
        }
    }
    &__parent {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
    }
    &__child {
        font-size: 14px;
        li {
            margin-bottom: 2px;
        }
    }
    &__subnav {
        display: flex;
        justify-content: flex-end;
        font-family: $font_mincho;
        font-size: 11px;
        @include media(sp) {
            display: block;
            text-align: center;
        }
    }
    &__sublink {
        margin-left: 25px;
        @include media(sp) {
            margin: 0 0 15px;
        }
    }
    &__inquiry {
        margin-bottom: 60px;
        a {
            @include media(pc) {
                min-width: 476px;
            }
            @include media(sp) {
                width: 100%;
            }
        }
    }
    &__page-top {
        display: none;
        width: 50px;
        height: 50px;
        background: url(/assets/img/pagetop.svg) center / contain no-repeat;
        text-align: center;
        position: fixed;
        bottom: 65px;
        right: 55px;
        z-index: 10;
        cursor: pointer;
        transition: transform 0.3s $easing01;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include media(pc) {
            &:hover {
                transform: translateY(-10px);
            }
        }
        @include media(sp) {
            width: 36px;
            height: 36px;
            right: 50%;
            top: -61px;
            transform: translateX(50%);
            position: absolute;
        }
        &.is-move {
            @include media(pc) {
                position: absolute;
                bottom: auto;
                top: -110px;
            }
        }
    }
}
