@charset "utf-8";
/* --------------------------------------------------
 Module CSS
 -------------------------------------------------- */

/* --------------------------------------------------
 Margin Padding
 -------------------------------------------------- */
.mt0 {
    margin-top: 0 !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt-50 {
    margin-top: -50px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb25 {
    margin-bottom: 25px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb1em {
    margin-bottom: 1em !important;
}

.mr0 {
    margin-right: 0 !important;
}
.mr5 {
    margin-right: 5px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr15 {
    margin-right: 15px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr25 {
    margin-right: 25px !important;
}
.mr30 {
    margin-right: 30px !important;
}

.ml0 {
    margin-left: 0 !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml15 {
    margin-left: 15px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml25 {
    margin-left: 25px !important;
}
.ml30 {
    margin-left: 30px !important;
}

.pt0 {
    padding-top: 0 !important;
}
.pb0 {
    padding-bottom: 0 !important;
}
.pr0 {
    padding-right: 0 !important;
}
.pl0 {
    padding-left: 0 !important;
}

/* --------------------------------------------------
 Margin Padding (responsive)
 -------------------------------------------------- */
.mt_s {
    margin-top: 20px !important;
    @include media(sp) {
        margin-top: 10px !important;
    }
}
.mt_sm {
    margin-top: 30px !important;
    @include media(sp) {
        margin-top: 15px !important;
    }
}
.mt_m {
    margin-top: 40px !important;
    @include media(sp) {
        margin-top: 20px !important;
    }
}
.mt_ml {
    margin-top: 50px !important;
    @include media(sp) {
        margin-top: 25px !important;
    }
}
.mt_l {
    margin-top: 60px !important;
    @include media(sp) {
        margin-top: 30px !important;
    }
}
.mt_ll {
    margin-top: 80px !important;
    @include media(sp) {
        margin-top: 40px !important;
    }
}
.mt_xl {
    margin-top: 100px !important;
    @include media(sp) {
        margin-top: 50px !important;
    }
}
.mb_s {
    margin-bottom: 20px !important;
    @include media(sp) {
        margin-bottom: 10px !important;
    }
}
.mb_sm {
    margin-bottom: 30px !important;
    @include media(sp) {
        margin-bottom: 15px !important;
    }
}
.mb_m {
    margin-bottom: 40px !important;
    @include media(sp) {
        margin-bottom: 20px !important;
    }
}
.mb_ml {
    margin-bottom: 50px !important;
    @include media(sp) {
        margin-bottom: 25px !important;
    }
}
.mb_l {
    margin-bottom: 60px !important;
    @include media(sp) {
        margin-bottom: 30px !important;
    }
}
.mb_ll {
    margin-bottom: 80px !important;
    @include media(sp) {
        margin-bottom: 40px !important;
    }
}
.mb_xl {
    margin-bottom: 100px !important;
    @include media(sp) {
        margin-bottom: 50px !important;
    }
}
.sp-mb0 {
    @include media(sp) {
        margin-bottom: 0 !important;
    }
}

/* --------------------------------------------------
 Set Style
 -------------------------------------------------- */
.clear {
    clear: both;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.tal {
    text-align: left !important;
}
.tar {
    text-align: right !important;
}
.tac {
    text-align: center !important;
}
.sp-tal{
    @include media(sp) {
        text-align: left !important;
    }
}
.justify {
    text-align: justify !important;
}
.bold {
    font-weight: $bold;
}
.hidden {
    display: none;
}
.visible {
    display: inherit;
}
.italic {
    font-style: italic;
}
.gothic {
    font-family: $font_gothic;
}
.mincho {
    font-family: $font_mincho;
}
.ls-normal {
    letter-spacing: normal !important;
}
.palt {
    font-feature-settings: "palt";
}
.block-center {
    margin-left: auto;
    margin-right: auto;
}
.indent p,
p.indent,
.indent li,
li.indent {
    text-indent: -1em;
    padding-left: 1em;
}
.indent2 p,
p.indent2,
.indent2 li,
li.indent2 {
    text-indent: -2em;
    padding-left: 2em;
}
.m-auto {
    margin: auto;
}
.display-table {
    display: table;
}
.fw {
    &-100 {
        font-weight: 100 !important;
    }
    &-300 {
        font-weight: 300 !important;
    }
    &-400 {
        font-weight: 400 !important;
    }
}
.align-start {
    align-items: flex-start;
}
.shadow {
    @include shadow01;
}

.txt {
    &--60p {
        font-size: 0.6em !important;
    }
    &--70p {
        font-size: 0.7em !important;
    }
    &--80p {
        font-size: 0.8em !important;
    }
    &--90p {
        font-size: 0.9em !important;
    }
    &--130p {
        font-size: 1.3em !important;
    }
    &--lead {
        font-size: 1.8rem;
        text-align: center;
        max-width: 900px;
        margin: auto;
        @include media(sp) {
            font-size: 1.6rem;
        }
    }
    &--white {
        color: #fff;
    }
    &--red {
        color: #f00;
    }
    &--blue {
        color: $site_color;
        a {
            color: $site_color;
        }
    }
}

/* --------------------------------------------------
 width
-------------------------------------------------- */
.maw700{max-width: 700px;}

/* --------------------------------------------------
 background
 -------------------------------------------------- */
.bg {
    &--white {
        background: #fff;
    }
}

/* --------------------------------------------------
 border
-------------------------------------------------- */
.bd {
    &--gray {
        border: 1px solid #666;
    }
    &--gray02 {
        border: 1px solid #ccc;
    }
}

/* --------------------------------------------------
 Link
-------------------------------------------------- */
.link {
    &--disable {
        pointer-events: none;
    }
    &--disable-pc {
        @media #{$pc} {
            pointer-events: none;
        }
    }
}

.click-box {
    @include hoverEffect01;
}

/* --------------------------------------------------
 object-fit
-------------------------------------------------- */
img.fit {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover;";
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
}

.fit-box {
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        font-family: "object-fit: cover;";
        -o-object-fit: cover;
        object-fit: cover;
        top: 0;
        left: 0;
    }
}

/* --------------------------------------------------
 Text box (pの下にマージンがつく)
-------------------------------------------------- */
.txt-box {
    > * {
        margin-bottom: 30px;
        @include media(sp) {
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &--mbS {
        > * {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/* --------------------------------------------------
 画像と説明文
-------------------------------------------------- */
figure {
    &.align-l {
        float: left;
        margin: 0 20px 30px 0;
        @include media(sp) {
            max-width: 100%;
            margin: 0 0 15px;
        }
    }
    &.align-r {
        float: right;
        margin: 0 0 30px 20px;
        @include media(sp) {
            max-width: 100%;
            margin: 0 0 15px;
        }
    }
    &.align-c {
        text-align: center;
        margin: 0 0 30px;
    }
    img {
        margin-bottom: 15px;
        @include media(sp) {
            margin-bottom: 5px;
        }
    }
    figcaption {
        @include media(sp) {
            font-size: 12px;
        }
    }
}

/* --------------------------------------------------
 横スクロールボックス
-------------------------------------------------- */
.scroll-box-x {
    @include media(sp) {
        overflow-x: auto;
        table {
            width: 600px;
        }
    }
}

/* --------------------------------------------------
 Responsive
-------------------------------------------------- */
.pc-show {
    display: inherit !important;
    &--flex {
        display: flex !important;
    }
    &--block {
        display: block !important;
    }
}
.tb-show {
    display: none !important;
}
.tb-sp-show {
    display: none !important;
}
.sp-show {
    display: none !important;
}
@media #{$tb} {
    .pc-show {
        display: inherit !important;
        &--flex {
            display: flex !important;
        }
        &--block {
            display: block !important;
        }
    }
    .tb-show {
        display: inherit !important;
    }
    .tb-sp-show {
        display: inherit !important;
    }
    .sp-show {
        display: none !important;
    }
}
@include media(sp) {
    .pc-show {
        display: none !important;
        &--flex {
            display: none !important;
        }
        &--block {
            display: none !important;
        }
    }
    .tb-show {
        display: none !important;
    }
    .tb-sp-show {
        display: inherit !important;
    }
    .sp-show {
        display: inherit !important;
    }
}

