/* --------------------------------------------------
 Wordpress
-------------------------------------------------- */

.pager {
    margin-top: 70px;
    text-align: center;
    @include media(sp) {
        margin-top: 40px;
    }
    .page-numbers {
        font-family: $font_mincho;
        font-size: 26px;
        display: inline-block;
        text-decoration: none;
        margin: 0 10px;
        padding: 0 5px;
        line-height: 1.5;
        @include media(sp) {
            font-size: 20px;
            margin: 0 8px;
            padding: 0 3px;
        }
        &.current {
            border-bottom: 2px solid #666;
        }
        &.next,
        &.prev {
            text-indent: -9999px;
            position: relative;
            &:before {
                content: "";
                height: 12px;
                width: 12px;
                display: block;
                border-top: 1px solid #666;
                border-right: 1px solid #666;
                transform: translateY(-50%) rotate(45deg);
                position: absolute;
                top: 57%;
                left: -6px;
                @include media(sp) {
                    height: 10px;
                    width: 10px;
                }
            }
        }
        &.prev {
            &:before {
                transform: translateY(-50%) rotate(-135deg);
                left: 2px;
            }
        }
    }
}

.post-header {
    @include margin-bottom(120px);
    &__type {
        font-size: 33px;
        font-family: $font_mincho;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
        @include media(sp) {
            font-size: 2.4rem;
            margin-bottom: 15px;
        }
    }
    &__img {
        @include margin-bottom(50px);
    }
    &__date {
        font-size: 24px;
        font-family: $font_mincho;
        margin-right: 25px;
        margin-bottom: 10px;
        display: inline-block;
        @include media(sp) {
            font-size: 20px;
        }
    }
    &__cat {
        display: inline-block;
        border: 1px solid $gray;
        border-radius: 5px;
        font-size: 16px;
        padding: 2px 15px 5px;
        line-height: 1.2;
        transform: translateY(-3px);
    }
    &__ttl {
        font-size: 3.2rem;
        font-family: $font_mincho;
        @include media(sp) {
            font-size: 2.2rem;
        }
    }
}
.content-area {
    word-break: break-word;
    @include margin-bottom(150px);
    p,
    ul,
    ol,
    figure {
        margin-bottom: 30px;
    }
    figure img {
        margin-bottom: 0;
    }
    figcaption {
        margin-top: 10px;
    }
    ul,
    ol {
        margin-left: 20px;
        ul,
        ol {
            margin-bottom: 0;
        }
        ul {
            list-style: circle;
        }
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    h2 {
        font-size: 2.4rem;
        font-weight: bold;
        margin: 50px 0 25px;
        border-bottom: 1px solid $site_color;
        @include media(sp) {
            font-size: 1.8rem;
            margin-bottom: 30px 0 15px;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
        padding-left: 20px;
        margin: 50px 0 15px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 2px;
            background: $site_color;
            top: 50%;
            left: 0;
        }
    }
    h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 40px 0 10px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.author {
    display: flex;
    gap: 40px;
    margin: -100px 0 150px;
    padding: 25px 35px;
    border: 1px solid #666;
    @include media(sp) {
        display: block;
        margin: -35px 0 50px;
        padding: 20px;
    }
    &__img {
        max-width: 158px;
        flex-shrink: 0;
        @include media(sp) {
            flex-shrink: 0;
            margin: 0 auto 10px;
        }
    }
    &__cont {
    }
    &__name {
        margin: 0 0 5px;
        @include media(pc) {
            font-size: 19px;
        }
        @include media(sp) {
            text-align: center;
        }
    }
    &__copy {
        margin-bottom: 15px;
        @include media(sp) {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    p {
        @include fz_s;
    }
}
.post-footer {
    &__nav {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include media(sp) {
        }
    }
    &__item {
        width: 33.33%;
        &.prev {
            @include media(sp) {
                width: 50%;
            }
        }
        &.next {
            text-align: right;
            @include media(pc) {
                order: 1;
            }
            @include media(sp) {
                width: 50%;
            }
        }
        &.back {
            text-align: center;
            @include media(sp) {
                margin: 40px auto 0;
                width: 100%;
            }
        }
    }
    &__btn {
        display: inline-block;
        font-family: $font_mincho;
        letter-spacing: 0.1em;
        position: relative;
        @include media(sp) {
            font-size: 14px;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 10px;
            border-bottom: 1px solid $gray;
            bottom: 0;
            left: 0;
            transition: all 0.3s $easing01;
        }
        &.prev {
            padding-left: 50px;
            @include media(sp) {
                padding-left: 40px;
            }
            &:after {
                transform: skewX(-45deg);
                transform-origin: bottom;
                border-left: 1px solid $gray;
                left: auto;
                right: 0;
            }
            &:hover {
                &:after {
                    width: 107%;
                }
            }
        }
        &.back {
        }
        &.next {
            padding-right: 50px;
            @include media(sp) {
                padding-right: 40px;
            }
            &:after {
                transform: skewX(45deg);
                transform-origin: bottom;
                border-right: 1px solid $gray;
            }
            &:hover {
                &:after {
                    width: 107%;
                }
            }
        }
    }
}
#included-files-fie-on-wp-footer {
    display: none;
}
