@charset "utf-8";

/* --------------------------------------------------
 フォームパーツ基本設定
-------------------------------------------------- */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
    width: 100%;
    border: none;
    padding: 8px 10px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #b1b1b1;
    border-radius: 0;
    font-size: 16px;
    font-family: $base_font;
    @include media(sp) {
        font-size: 14px;
    }
    &.input--half {
        @include media(pc) {
            display: inline-block;
            width: 49%;
        }
        @include media(sp) {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}
textarea {
    height: 470px;
    @include media(sp) {
        height: 240px;
    }
}
button {
    font-family: inherit;
    @include hoverEffect01;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
    border: 0;
    padding-right: 30px;
    line-height: 1.2;
    font-family: $base_font;
    background: transparent;
}

/* --------------------------------------------------
 フォームレイアウト用
-------------------------------------------------- */
.form-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__row {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        width: 100%;
        @include media(sp) {
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        &--block {
            display: block;
            .form-box__ttl {
                margin-bottom: 15px;
                width: 100%;
            }
            .form-box__item {
                width: 100%;
            }
        }
        .form-box--confirm &,
        .mw_wp_form_preview & {
            margin-bottom: 0;
            padding: 25px 0;
            border-bottom: 1px solid #ccc;
        }
    }
    &__ttl {
        width: 24%;
        padding-top: 0.25em;
        letter-spacing: 0.1em;
        @include media(sp) {
            width: 100%;
            margin-bottom: 5px;
            padding-top: 0;
        }
        .required {
            &:after {
                content: "※";
                font-size: 0.9em;
                color: #f00;
                margin-left: 5px;
                transform: translateY(2px);
            }
        }
        .form-box--confirm &,
        .mw_wp_form_preview & {
            padding-top: 0;
        }
        &--normal {
            letter-spacing: normal;
            font-feature-settings: "palt";
        }
        &__notes {
            color: #f00;
            order: 1;
            @include fz_s;
            text-indent: -1.2em;
            padding-left: 1.2em;
            .mw_wp_form_preview & {
                display: none;
            }
        }
    }
    &__notes {
        display: block;
        font-weight: normal;
        font-size: 1.3rem;
    }
    &__item {
        width: 76%;
        @include media(sp) {
            width: 100%;
        }
        &--flex {
            display: flex;
            flex-wrap: wrap;
            gap: 0 2%;
            input,
            label {
                width: 49%;
            }
            label {
                @include media(sp) {
                    width: 100%;
                }
            }
        }
    }
    &__option {
        display: none;
        margin-bottom: 30px;
        padding-left: 30px;
        .mw_wp_form_preview & {
            display: block;
        }
    }
    &__btn {
        @include margin-top(80px);
        &--confirm,
        .mw_wp_form_preview & {
            @include media(pc) {
                display: flex;
                justify-content: space-between;
                button {
                    min-width: 300px;
                    margin: 0 !important;
                }
            }
        }
        &--back {
            &:after {
                right: auto;
                left: 18px;
                transform: translateY(-50%) scaleX(-1) !important;
            }
            &:hover {
                &:after {
                    transform: translate(-8px, -50%) scaleX(-1) !important;
                }
            }
        }
    }
    &__parent {
        font-size: 20px;
        margin-bottom: 5px;
        font-weight: $bold;
        @include media(sp) {
            font-size: 18px;
        }
    }
}
.form-privacy {
    margin: 80px 0 40px;
    @include media(sp) {
        margin: 40px 0 20px;
    }
    p {
        @include fz_s;
        margin-bottom: 20px;
    }
    .mw_wp_form_preview & {
        display: none;
    }
}
.form-agreement {
    text-align: center;
    .mw_wp_form_preview & {
        display: none;
    }
}

.input {
    &--w80 {
        max-width: 80px;
        margin-right: 10px;
    }
    &--w160 {
        max-width: 160px;
        margin-right: 10px;
        @include media(sp) {
        }
    }
    &__note {
        font-size: 1.4rem;
        display: inline-block;
    }
}

/* --------------------------------------------------
 フォームパーツ装飾
-------------------------------------------------- */

.check01,
.check02 {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    @include media(sp) {
    }
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    > span {
        &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: #fff;
            border: 1px solid #b1b1b1;
            position: absolute;
            top: 3px;
            left: 0;
            @include media(sp) {
            }
        }
        &:after {
            content: "";
            display: none;
            width: 14px;
            height: 14px;
            background: #000;
            position: absolute;
            top: 7px;
            left: 4px;
            @include media(sp) {
            }
        }
    }
    input[type="checkbox"],
    input[type="radio"] {
        //display: noneにするとバリデーションが出ないので表示しておく
        // display: none;
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + span {
            &:after {
                display: block;
            }
        }
        &:disabled + span {
            &:before {
                opacity: 0.5;
            }
            &:after {
                opacity: 0.3;
            }
        }
    }
    &--center {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
}
.check02 {
    > span {
        &:before {
            width: 22px;
            height: 22px;
            border-radius: 50px;
        }
        &:after {
            top: 8px;
            left: 5px;
            border-radius: 50px;
            @include media(sp) {
            }
        }
    }
    &--parent {
        font-size: 20px;
        font-weight: $bold;
        @include media(sp) {
            font-size: 18px;
        }
    }
}

.select-wrap {
    position: relative;
    z-index: 0;
    display: inline-block;
    cursor: pointer;
    &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-top: 1px solid #b1b1b1;
        border-right: 1px solid #b1b1b1;
        position: absolute;
        right: 3px;
        top: 30%;
        transform: translateY(-50%) rotate(135deg);
        z-index: -1;
        @include media(sp) {
            width: 10px;
            height: 10px;
        }
    }
}


/* --------------------------------------------------
 エラー
-------------------------------------------------- */
.error {
    color: #f00 !important;
    font-size: 0.9em !important;
}
