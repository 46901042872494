/* --------------------------------------------------
下層ページ お問い合わせ inquiry 追加スタイル
-------------------------------------------------- */
.completion{
    .ttl01{
        @include media(sp) {
            font-size: 24px;
        }
    }
    .btn01{
        a{
            @include media(pc) {
                min-width: 340px;
            }
        }
    }
}

