// ===================================================================
// parts
// ===================================================================
.section-ttl {
    font-size: 2.1rem;
    margin-bottom: 120px;
    @include media(sp) {
        font-size: 1.8rem;
        margin-bottom: 60px;
    }
}
.ttl01 {
    font-size: 3.8rem;
    font-family: $font_mincho;
    text-align: center;
    letter-spacing: 0.4em;
    @include margin-bottom(30px);
    @include media(sp) {
        font-size: 3rem;
    }
    &__sub {
        font-size: 28px;
        display: block;
        @include media(sp) {
            font-size: 2rem;
        }
    }
    &--ls02 {
        letter-spacing: 0.2em;
    }
    &--type01 {
        letter-spacing: 0.2em;
        @include media(sp) {
            font-size: 2.6rem;
        }
    }
}
.ttl02 {
    font-size: 3.5rem;
    font-family: $font_mincho;
    line-height: 1.6;
    color: $site_color;
    text-align: center;
    letter-spacing: 0.2em;
    margin-bottom: 30px;
    @include media(sp) {
        font-size: 2rem;
        margin-bottom: 15px;
    }
    &__small {
        font-size: 80%;
    }
    &__sub {
        color: #fff;
        @include grd01;
        display: table;
        font-size: 18px;
        min-width: 200px;
        margin: 0 auto 20px;
        padding: 8px 15px;
        font-family: $font_gothic;
        line-height: 1.2;
        @include media(sp) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }
    &__notes {
        font-size: 16px;
        color: #000;
        font-family: $font_gothic;
        display: block;
        letter-spacing: normal;
        margin-top: 15px;
    }
    &--arrow {
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-top: 2px solid $site_color;
            border-right: 2px solid $site_color;
            position: absolute;
            left: calc(50% - 0.1em);
            bottom: -16px;
            transform: translateX(-50%) rotate(135deg) skew(-3deg, -3deg);
            @include media(sp) {
                width: 12px;
                height: 12px;
                bottom: -10px;
            }
        }
    }
}
.ttl03 {
    font-size: 2.6rem;
    font-family: $font_mincho;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 35px;
    letter-spacing: 0.1em;
    @include media(sp) {
        font-size: 2rem;
        margin-bottom: 20px;
        line-height: 1.5;
    }
}
.ttl04 {
    font-size: 2.2rem;
    text-align: center;
    font-weight: $bold;
    margin: 0 auto 35px;
    padding-bottom: 5px;
    display: table;
    @include media(sp) {
        font-size: 2rem;
        margin-bottom: 20px;
        line-height: 1.5;
    }
    &--line {
        border-bottom: 1px solid $gray;
    }
    &--type01 {
        border-bottom: 1px solid $gray;
        text-align: left;
        width: 100%;
    }
}
.ttl05 {
    //下層ページタイトル 小さめ
    font-size: 28px;
    font-family: $font_mincho;
    text-align: center;
    letter-spacing: 0.3em;
    margin-bottom: 45px;
    @include media(sp) {
        font-size: 24px;
        margin-bottom: 30px;
    }
}
.ttl06 {
    //下層ページh2
    font-size: 33px;
    font-family: $font_mincho;
    text-align: center;
    letter-spacing: 0.25em;
    margin-bottom: 30px;
    @include media(sp) {
        font-size: 24px;
        margin-bottom: 30px;
    }
}
.ttl07 {
    //下層ページタイトル 大きめ
    font-size: 46px;
    font-family: $font_mincho;
    text-align: center;
    letter-spacing: 0.3em;
    margin-bottom: 45px;
    @include media(sp) {
        font-size: 24px;
        margin-bottom: 30px;
    }
}
.ttl08 {
    //下線
    font-size: 2.6rem;
    text-align: center;
    margin: 0 auto 50px;
    padding-bottom: 5px;
    letter-spacing: 0.25em;
    display: table;
    @include media(sp) {
        font-size: 2rem;
        margin-bottom: 20px;
        line-height: 1.5;
    }
    &--line {
        border-bottom: 1px solid $gray;
    }
    &--dot {
        border-bottom: none;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background: url(/assets/img/line01.png) 0 0 repeat-x;
        }
    }
}
.ttl09 {
    //
    font-size: 51px;
    font-family: $font_mincho;
    color: $site_color;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 60px;
    @include media(sp) {
        font-size: 24px;
        margin-bottom: 30px;
    }
    &__sub {
        font-size: initial;
        color: initial;
        font-family: initial;
        display: block;
        @include media(sp) {
            font-size: 14px;
        }
    }
}
.ttl10 {
    font-size: 24px;
    text-align: center;
    font-weight: $bold;
    letter-spacing: 0.1em;
    margin-bottom: 30px;
    @include media(sp) {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
.ttl11 {
    border: 1px solid $gray;
    display: table;
    padding: 0px 30px;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
    line-height: 1.5;
    @include media(sp) {
    }
}

.btn01 {
    a,
    button {
        color: $site_color;
        text-align: center;
        font-size: 2.1rem;
        border: 1px solid;
        border-radius: 50px;
        letter-spacing: 0.15em;
        line-height: 1.2;
        margin: auto;
        padding: 13px 70px;
        display: table;
        min-width: 410px;
        position: relative;
        transition: all 0.5s $easing01;
        @include media(tb) {
            padding: 13px 40px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            min-width: 0;
            width: 100%;
            font-size: 1.6rem;
        }
        &:hover {
            background: $site_color;
            color: #fff;
            opacity: 1;
            &:after {
                background-image: url(/assets/img/icon_arrow02_white.svg);
                transform: translate(8px, -50%);
            }
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 48px;
            height: 10px;
            background: url(/assets/img/icon_arrow02.svg) center / contain no-repeat;
            top: 45%;
            right: 18px;
            transform: translateY(-50%);
            transition: transform 0.5s $easing01;
            @include media(tb) {
                width: 24px;
                right: 13px;
            }
        }
        & + a,
        & + button {
            @include margin-top(30px);
        }
        &.min-476 {
            @include media(pc) {
                min-width: 476px;
            }
        }
    }
    &--s {
        a,
        button {
            font-size: 1.8rem;
            padding: 8px 70px;
            min-width: 0;
            letter-spacing: 0.1em;
            @include media(sp) {
                font-size: 1.6rem;
            }
            &:after {
                width: 36px;
            }
        }
    }
    &--noarrow {
        a,
        button {
            &:after {
                display: none;
            }
        }
    }
    &--flex {
        @include media(pc) {
            display: flex;
        }
        a,
        button {
            width: 100%;
            min-width: 0;
            margin-top: 0 !important;
            &:not(:last-child) {
                margin-right: 30px;
                @include media(sp) {
                    margin: 0 0 15px;
                }
            }
        }
    }
}
.btn02 {
    a {
        font-family: $font_mincho;
        letter-spacing: 0.1em;
        display: inline-block;
        padding-right: 70px;
        position: relative;
        cursor: pointer;
        @include media(sp) {
            max-width: none;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 63px;
            height: 10px;
            background: url(/assets/img/icon_arrow01.svg) center / contain no-repeat;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: right 0.5s $easing01;
        }
        &:hover {
            &:after {
                right: -30px;
            }
        }
    }
}
.btn03 {
    display: flex;
    flex-wrap: wrap;
    a,
    span {
        color: #fff;
        text-align: center;
        font-family: $font_mincho;
        letter-spacing: -0.01em;
        padding: 17px 0;
        display: inline-block;
        width: 48.8%;
        @include grd01;
        @include media(sp) {
            width: 100%;
            line-height: 1.3;
        }
        &:nth-child(2n + 1) {
            margin-right: 2.4%;
            @include media(sp) {
                margin: 0 0 15px;
            }
        }
        &:hover {
        }
    }
    &--s {
        a,
        button {
            font-size: 1.8rem;
            padding: 8px 50px;
            min-width: 0;
            letter-spacing: 0.1em;
        }
    }
}
.btn04 {
    display: flex;
    flex-wrap: wrap;
    a,
    span {
        padding: 10px 18px;
        display: inline-block;
        border: 1px solid $gray;
        line-height: 1.2;
        @include fz_s;
        @include media(sp) {
            padding: 10px;
        }
        &:hover {
        }
    }
    &--s {
        a,
        button {
            font-size: 1.8rem;
            padding: 8px 50px;
            min-width: 0;
            letter-spacing: 0.1em;
        }
    }
}
.btn05 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 30px;
    a,
    span {
        color: #fff;
        background: $site_color;
        text-align: center;
        letter-spacing: -0.01em;
        padding: 26px 0;
        display: block;
        width: calc((100% - 30px) / 2);
        @include media(sp) {
            width: 100%;
            line-height: 1.3;
        }
        &:hover {
        }
    }
    &--s {
        a,
        button {
            font-size: 1.8rem;
            padding: 8px 50px;
            min-width: 0;
            letter-spacing: 0.1em;
        }
    }
}
.btn06 {
    font-family: $font_mincho;
    text-align: center;
    letter-spacing: 0.1em;
}

@keyframes arrow-move01 {
    0%,
    100% {
        transform: translate(0, -50%) rotate(45deg);
    }

    45%,
    55% {
        transform: translate(15px, -50%) rotate(45deg);
    }

    // 61% {
    //     opacity: 0;
    //     -webkit-transform: translate(-15px, -50%);
    //     transform: translate(-15px, -50%)
    // }

    56% {
        opacity: 1;
        transform: translate(-15px, -50%) rotate(45deg);
    }
}
.list01 {
    //news
    &__item {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #9f9f9f;
        position: relative;
        overflow: hidden;
        @include media(sp) {
            padding: 20px 0;
        }
        &:after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            @include media(sp) {
                width: 12px;
                height: 12px;
            }
        }
        &:hover,
        &.is-anime {
            &:after {
                animation: arrow-move01 0.5s $easing02 forwards;
            }
        }
        &:nth-child(1) {
            border-top: 1px solid #9f9f9f;
        }
    }
    &__img {
        width: 22%;
        margin-right: 8%;
        flex-shrink: 0;
        @include objectFitBox(56.25%);
        @include media(sp) {
            width: 33%;
            margin-right: 3%;
        }
    }
    &__cont {
        padding-right: 60px;
        @include media(sp) {
            padding-right: 25px;
        }
    }
    &__date {
        display: inline-block;
        margin-right: 30px;
        flex-shrink: 0;
        @include media(sp) {
            padding: 0;
            font-size: 14px;
        }
    }
    &__cat {
        display: inline-block;
        margin-right: 20px;
        @include media(sp) {
            font-size: 14px;
        }
    }
    &__ttl {
        @include media(sp) {
            margin-top: 5px;
            width: 100%;
            font-size: 14px;
        }
    }
}
.list02 {
    font-family: $font_mincho;
    text-align: center;
    &__ttl {
        display: table;
        margin: 0 auto 20px;
        font-size: 2.2rem;
        letter-spacing: 0.2em;
        position: relative;
        @include media(sp) {
            font-size: 1.8rem;
            margin-bottom: 10px;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background: url(/assets/img/line01.png) 0 0 repeat-x;
        }
    }
    &__txt {
        font-size: 2rem;
        letter-spacing: 0.1em;
        @include media(sp) {
            font-size: 1.4rem;
        }
        & + .list02__ttl {
            margin-top: 50px;
            @include media(sp) {
                margin-top: 30px;
            }
        }
    }
}
.list03 {
    padding-left: 45px;
    @include media(sp) {
        padding-left: 30px;
    }
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    &__ttl {
        margin-bottom: 10px;
        font-size: 25px;
        color: $site_color;
        font-family: $font_mincho;
        letter-spacing: 0.2em;
        border-bottom: 1px solid #666;
        position: relative;
        @include media(sp) {
            font-size: 20px;
        }
    }
    &__cont {
        font-size: 14px;
        letter-spacing: -0.1em;
    }
    &__num {
        position: absolute;
        left: -45px;
        top: 0;
        font-size: 52px;
        line-height: 1;
        @include media(sp) {
            font-size: 32px;
            left: -30px;
        }
    }
}
.list04 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;
    &__item {
        width: calc((100% - 60px) / 4);
        max-width: 400px;
        height: 192px;
        text-align: center;
        font-size: 16px;
        position: relative;
        @include shadowBox01;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media(sp) {
            width: 100%;
            height: auto;
            min-height: 118px;
            padding: 15px 5px;
        }
        // &:before {
        //     content: "";
        //     display: block;
        //     @include shadowBox01;
        //     width: 100%;
        //     height: 100%;
        //     margin: auto;
        //     @include media(sp) {
        //     }
        // }
        .list04--col3 & {
            @include media(pc) {
                width: calc((100% - 40px) / 3);
            }
        }
        .list04--col2 & {
            @include media(pc) {
                width: calc((100% - 20px) / 2);
            }
        }
        .list04--spcol1 & {
            @include media(sp) {
                width: 100%;
                &:before {
                }
            }
        }
    }
    &__inner {
    }
    &__notes {
        font-size: 13px;
        position: absolute;
        bottom: 0;
        right: 0;
        @include media(sp) {
            font-size: 11px;
            position: static;
            margin-left: auto;
        }
    }
}
.list05 {
    display: flex;
    flex-wrap: wrap;
    &__item {
        width: 32.1%;
        font-size: 1.6rem;
        margin-bottom: 15px;
        border: 1px solid #666;
        text-align: center;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 1.5;
        @include media(tb) {
            font-size: 14px;
        }
        @include media(sp) {
            width: 100%;
            margin: 0 0 15px;
            height: auto;
            padding: 10px 0px;
        }
        @media #{$pc} {
            &:not(:nth-child(3n)) {
                margin-right: 1.85%;
            }
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
    }
}
.list06 {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $gray;
    @include media(sp) {
        display: block;
        border: 0;
        margin-bottom: 15px;
    }
    &:first-child {
        @include media(pc) {
        }
    }
    &:last-child {
        @include media(sp) {
            margin-bottom: 0;
        }
    }
    &__ttl,
    &__item {
        border-bottom: 1px solid $gray;
    }
    &__ttl {
        width: 24%;
        font-weight: $bold;
        padding: 15px 15px 15px 30px;
        flex-shrink: 0;
        flex-grow: 0;
        @include media(sp) {
            padding: 0;
            border: 0;
            width: 100%;
        }
    }
    &__item {
        width: 76%;
        padding: 15px 30px 15px 0;
        @include media(sp) {
            padding: 15px 0;
            margin: 0 0 15px;
            width: 100%;
        }
    }
}
.list07 {
    li {
        margin-bottom: 30px;
    }
}
.list08 {
    //news
    &__item {
        display: flex;
        align-items: center;
        padding: 40px 0;
        border-bottom: 1px solid #9f9f9f;
        position: relative;
        overflow: hidden;
        @include media(sp) {
            flex-wrap: wrap;
            padding: 20px 0;
        }
        &:after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        }
        &:hover {
            &:after {
                animation: arrow-move01 0.5s $easing02 forwards;
            }
        }
        &:nth-child(1) {
            border-top: 1px solid #9f9f9f;
        }
    }
    &__img {
        width: 30%;
        margin-right: 4%;
        flex-shrink: 0;
        @include objectFitBox(67.4%);
    }
    &__cont {
        padding-right: 90px;
    }
    &__ttl {
        font-size: 22px;
        font-family: $font_mincho;
        margin-bottom: 15px;
        @include media(sp) {
            margin-top: 10px;
            width: 100%;
        }
    }
    &__description {
        font-size: 16px;
    }
}
.list09 {
    &__row {
        display: flex;
        margin-bottom: 50px;
        @include media(sp) {
            display: block;
            border: 0;
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__ttl,
    &__item {
    }
    &__ttl {
        font-size: 21px;
        color: #fff;
        background: $site_color;
        text-align: center;
        min-width: 210px;
        padding: 15px 0;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media(sp) {
            padding: 10px;
            font-size: 18px;
        }
    }
    &__item {
        flex-grow: 1;
        padding-left: 20px;
        @include media(sp) {
            padding: 15px;
        }
    }
}
.list10 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 30px;
    position: relative;
    &__item {
        width: calc((100% - 60px) / 3);
        height: 153px;
        border: 1px solid #666;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        position: relative;
        @include media(sp) {
            width: 100%;
            height: auto;
            min-height: 118px;
            padding: 15px 10px;
        }
        &--w100 {
            width: 100% !important;
            @include media(sp) {
                height: auto !important;
            }
        }
        .list10--col4 & {
            width: calc((100% - 60px) / 4);
            height: 172px;
            @include media(tb) {
                width: calc((100% - 20px) / 2);
            }
            @include media(sp) {
                width: 100%;
                height: auto;
            }
        }
        .list10--type01 & {
            @include media(pc) {
                width: calc((100% - 32px) / 3);
                height: 172px;
            }
        }
        .list10--type02 & {
            border: none;
            background: #c8c8c8;
            border-radius: 100px;
            font-size: inherit;
            @include media(pc) {
                width: calc((100% - 32px) / 3);
                height: 110px;
            }
            @include media(tb) {
                font-size: 16px;
            }
        }
        .list10--type03 & {
            border: none;
            background: #fff;
            font-size: inherit;
            @include media(pc) {
                width: calc((100% - 48px) / 3);
                height: 126px;
            }
            @include media(tb) {
                font-size: 16px;
            }
        }
    }
    &--col4 {
        gap: 20px;
    }
    &--center {
        justify-content: center;
    }
    &--type01 {
        gap: 16px;
    }
    &--type02 {
        gap: 16px;
    }
    &--type03 {
        gap: 24px;
    }
    &__inner {
        display: block;
    }
    &__notes {
        height: auto;
        border: 0;
        font-size: 13px;
        position: absolute;
        bottom: 0;
        right: 0;
        @include media(sp) {
            font-size: 11px;
            position: static;
            text-align: right;
            width: 100%;
        }
    }
}
.list11 {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 20px;
    position: relative;
    @include media(sp) {
        gap: 30px;
    }
    &__item {
        text-align: center;
        position: relative;
        @include media(sp) {
            width: 100%;
            height: auto;
            min-height: 118px;
        }
    }
    .portlait {
        @include media(sp) {
            width: 80%;
            margin: auto;
        }
    }
    .landscape {
    }
}
.list12 {
    &__ttl {
        font-weight: $bold;
        margin-bottom: 5px;
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
    &__cont {
        padding-left: 1em;
        @include fz_s;
    }
}
.list13 {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 2;
    &__item {
        &:not(:last-child) {
            &:after {
                content: "／";
                margin: 0 8px;
            }
        }
    }
}

.list-num {
    &--period {
        margin-left: 1.6em;
        > li {
            list-style: decimal;
            margin-bottom: 0.5em;
            &::marker {
                letter-spacing: 0.075em;
            }
        }
    }
    &--bracket {
        margin-left: 3em;
        > li {
            list-style: none;
            counter-increment: count;
            margin-bottom: 0.5em;
            &:before {
                content: "（" counter(count) "）";
                // content: "(" counter(count) ")";
                width: 3em;
                margin-left: -3em;
                display: inline-block;
            }
        }
    }
    &--hyphen {
        margin-left: 3em;
        > li {
            list-style: none;
            margin-bottom: 0.5em;
            position: relative;
            &:before {
                content: attr(data-title);
                width: 3em;
                margin-left: -3em;
                display: inline-block;
                position: absolute;
            }
        }
    }
}

.box01 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media(sp) {
        display: block;
    }
    &__item {
        width: 48.4%;
        @include shadowBox01;
        margin-bottom: 30px;
        padding: 40px 72px 25px;
        display: flex;
        flex-direction: column;
        @include media(sp) {
            width: 100%;
            padding: 30px;
        }
        @include media(tb) {
            padding: 30px;
        }
    }
    &__img {
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }
    &__ttl {
        font-weight: $bold;
        margin-bottom: 15px;
        font-size: 21px;
        line-height: 1.5;
    }
    &__txt {
        font-size: 15px;
        margin-bottom: 40px;
    }
    .btn01 {
        margin-top: auto;
        a {
            width: 100%;
            margin: 0;
        }
    }
}
.box02 {
    display: flex;
    justify-content: space-between;
    gap: 0 70px;
    @include media(sp) {
        display: block;
    }
    &__item {
        width: calc((100% - 140px) / 3);
        @include media(sp) {
            width: 100%;
            margin-bottom: 30px;
        }
        .box02--col2 & {
            @include media(pc) {
                width: calc((100% - 70px) / 2);
            }
        }
    }
    &__balloon {
        // background: url(/assets/img/bg_box02.png) center / contain no-repeat;
        background: url(/assets/img/bg_box02_round.png) center / contain no-repeat;
        margin-bottom: 12px;
        position: relative;
        @include media(sp) {
            font-size: 22px;
            width: 56vw;
            margin: 0 auto 12px;
        }
        &:before {
            content: "";
            display: block;
            // padding-top: 83.1%;
            padding-top: 108%;
        }
        .box02--circle & {
            background-image: url(/assets/img/bg_box02_circle.png);
            &:before {
                padding-top: 100%;
            }
        }
        .box02--col2 & {
            background-image: url(/assets/img/bg_box02_col2.png);
            @include media(sp) {
                width: 100%;
            }
            &:before {
                padding-top: 62.2%;
            }
        }
    }
    &__ttl {
        font-size: 26px;
        font-family: $font_mincho;
        letter-spacing: 0.1em;
        text-align: center;
        color: #fff;
        line-height: 1.5;
        padding-bottom: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        @include media(sp) {
            font-size: 20px;
        }
        .box02--circle & {
            font-size: 21px;
            top: 40px;
            transform: translate(-50%, 0);
            @include media(sp) {
                top: 30px;
            }
        }
        .box02--col2 & {
            letter-spacing: normal;
            @include media(tb) {
                font-size: 20px;
            }
        }
    }
    &__num {
        display: block;
        font-size: 42px;
    }
    &--col2 {
    }
}
.box03 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 30px 70px 30px 40px;
    @include media(sp) {
        padding: 30px 20px !important;
        flex-wrap: wrap;
    }
    & + & {
        margin-top: 90px;
        @include media(sp) {
            margin-top: 40px;
        }
    }
    &__img {
        width: 34%;
        text-align: center;
        @include media(sp) {
            width: 100%;
            margin-bottom: 15px;
            text-align: center;
        }
        img {
            margin-bottom: 15px;
            @include media(sp) {
                width: 50%;
            }
        }
    }
    &__cont {
        width: 57%;
        @include media(sp) {
            width: 100%;
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        padding: 30px 40px 30px 70px;
    }
}
.box04 {
    background: #fff;
    padding: 60px 70px 80px;
    @include media(sp) {
        padding: 20px;
        flex-wrap: wrap;
    }
    & + & {
        margin-top: 90px;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
    &__profile {
        width: 33%;
        @include media(sp) {
            width: 100%;
            margin: 0 auto 15px;
        }
        img {
            margin-bottom: 20px;
            display: block;
            margin: 0 auto 15px;
            @include media(sp) {
                width: 180px;
            }
        }
    }
    &__name {
        font-weight: $bold;
        margin-bottom: 15px;
    }
    &__cont {
        width: 60%;
        @include media(sp) {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    &--reverse {
        flex-direction: row-reverse;
        padding: 30px 40px 30px 70px;
    }
}
.box05 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media(sp) {
        display: block;
    }
    &__ttl {
        font-weight: $bold;
        border: 1px solid $gray;
        margin-bottom: 15px;
        padding: 4px;
        text-align: center;
    }
    &__txt {
        font-size: 16px;
        margin-bottom: 15px;
    }
    &__item {
        width: 45%;
        @include media(pc) {
            &:nth-child(n + 3) {
                margin-top: 100px;
            }
        }
        @include media(sp) {
            width: 100%;
            &:nth-child(n + 2) {
                margin-top: 50px;
            }
        }
    }
    &__img {
        margin: 0 auto 20px;
        text-align: center;
        display: block;
        width: 76%;
        // @include shadow01;
    }
    .btn01 {
        margin-top: auto;
        a {
            width: 100%;
            margin: 0;
        }
    }
}
.box06 {
    border: 1px solid $gray;
    padding: 30px 40px;
    @include media(sp) {
        padding: 20px;
    }
    &--s {
        padding: 20px 30px;
        @include media(sp) {
            padding: 20px;
        }
    }
    &--l {
        padding: 40px 50px 50px;
        @include media(sp) {
            padding: 20px;
        }
    }
    &--white {
        @include shadowBox01;
        border: 0;
    }
}
.box07 {
    max-width: 584px;
    margin: auto;
    @include media(sp) {
        display: block;
    }
    &__ttl {
        font-weight: $bold;
        border: 1px solid $gray;
        margin-bottom: 15px;
        padding: 6px;
        text-align: center;
    }
    &__item {
        &:not(:last-child) {
            margin-bottom: 70px;
        }
    }
    &__balloon {
        background: $site_color;
        color: #fff;
        text-align: center;
        max-width: 248px;
        border-radius: 50px;
        margin: 0 auto 20px;
        padding: 2px;
        letter-spacing: 0.2em;
        position: relative;
        @include media(sp) {
            font-size: 16px !important;
        }
        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 5px 0 5px;
            border-color: $site_color transparent transparent transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.box08 {
    border-top: 1px solid #9b969b;
    border-left: 1px solid #9b969b;
    padding: 50px 50px 80px;
    background: url(/assets/img/box08_corner.svg) right bottom / 88px no-repeat;
    position: relative;
    @include media(sp) {
        padding: 20px 20px 50px;
        background-size: 44px;
    }
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        background: #9b969b;
    }
    &:before {
        //tate
        width: 1px;
        height: calc(100% - 88px);
        right: 0;
        top: 0;
        @include media(sp) {
            height: calc(100% - 44px);
        }
    }
    &:after {
        //yoko
        width: calc(100% - 88px);
        height: 1px;
        left: 0;
        bottom: 0;
        @include media(sp) {
            width: calc(100% - 44px);
        }
    }
    & + & {
        @include margin-top(70px);
    }
    &__ttl {
        color: #fff;
        @include grd01;
        font-size: 25px;
        font-family: $font_mincho;
        max-width: 600px;
        text-align: center;
        margin: 0 auto 30px;
        padding: 20px;
        @include media(sp) {
            margin-bottom: 15px;
            padding: 10px 10px 15px;
            font-size: 20px;
            line-height: 1.2;
        }
        &__num {
            font-size: 1.3em;
            margin-right: 30px;
            transform: translateY(2px);
            display: inline-block;
            @include media(sp) {
                transform: none;
            }
        }
    }
}
.box09 {
    &__item {
        display: flex;
        justify-content: space-between;
        @include media(sp) {
            flex-wrap: wrap;
        }
        & + & {
            margin-top: 60px;
            padding-top: 60px;
            border-top: 1px solid $gray;
            @include media(sp) {
                margin-top: 40px;
                padding-top: 40px;
            }
        }
    }
    &__img {
        width: 25%;
        @include media(sp) {
            width: 240px;
            margin: 0 auto 15px;
        }
    }
    &__ttl {
        @include grd01;
        color: #fff;
        padding: 6px 15px;
        line-height: 1.4;
    }
    &__position {
        display: block;
        @include fz_s;
        .tar {
            display: block;
        }
    }
    &__name {
        display: block;
        text-align: right;
    }
    &__cont {
        width: 70%;
        @include fz_s;
        @include media(pc) {
            padding-top: 8px;
        }
        @include media(sp) {
            width: 100%;
        }
    }
}
.box10 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 35px;
    @include media(tb) {
        padding: 20px;
    }
    @include media(sp) {
        display: block;
        padding: 10px;
    }
    &__txt {
        min-width: 260px;
        text-align: left;
        flex-shrink: 0;
        @include media(sp) {
            width: 100%;
            text-align: center;
        }
    }
    &__arrow {
        width: 23px;
        height: 40px;
        margin: 0 30px;
        position: relative;
        @include media(tb) {
            margin: 0 20px;
        }
        @include media(sp) {
            width: 100%;
            margin: 0;
        }
        &:after {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            border-top: 1px solid $gray;
            border-right: 1px solid $gray;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            @include media(sp) {
                width: 20px;
                height: 20px;
                transform: translate(-50%, 0) rotate(135deg);
                left: 50%;
                top: 10%;
            }
        }
    }
    &__inner {
        display: inline-block;
        text-align: left;
    }
    &__item {
        @include media(tb) {
            width: 45%;
        }
        @include media(sp) {
            width: 100%;
        }
    }
}
.box11 {
    display: flex;
    flex-wrap: wrap;
    gap: 110px 50px;
    @include media(sp) {
        gap: 40px 0;
    }
    &__item {
        width: calc((100% - 100px) / 3);
        @include media(sp) {
            width: 100%;
        }
    }
    &__link {
        display: block;
    }
    &__img {
        @include objectFitBox(64.2%);
        margin-bottom: 10px;
    }
    &__date {
        display: inline-block;
        margin: 0 15px 10px 0;
    }
    &__cat {
        display: inline-block;
        margin-bottom: 10px;
        &--type01 {
            border: 1px solid $gray;
            border-radius: 5px;
            font-size: 10px;
            font-family: $font_gothic;
            padding: 1px 5px;
            padding: 3px 5px;
            line-height: 1.2;
            transform: translateY(-3px);
        }
    }
    &__ttl {
    }
}
.box12 {
    &__inner {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #666;
        border-bottom: 1px solid #666;
        padding: 30px 0;
        @include media(sp) {
            display: block;
            padding: 20px 0;
        }
    }
    &__cont {
        width: 68%;
        @include media(sp) {
            width: 100%;
        }
    }
    &__ttl {
        font-size: 30px;
        text-align: center;
        margin-bottom: 15px;
        letter-spacing: 0.2em;
        @include media(sp) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    &__name {
        font-size: 27px;
        font-family: $font_mincho;
        margin-bottom: 10px;
        @include media(sp) {
            font-size: 20px;
        }
        span {
            letter-spacing: -0.05em;
        }
    }
    &__txt {
    }
    &__img {
        width: 32%;
        text-align: center;
        @include media(sp) {
            width: 100%;
            margin-bottom: 15px;
        }
        img {
            @include media(pc) {
                // width: 198px;
                max-width: 70%;
            }
        }
    }
    &__link {
        display: table;
        margin: 30px auto 0;
        font-size: 21px;
        @include media(sp) {
            font-size: 18px;
            margin: 20px auto 0;
        }
    }
    &__balloon {
        background: $site_color;
        color: #fff;
        text-align: center;
        margin-bottom: 10px;
        padding: 5px;
    }
}

.table01 {
    width: 100%;
    th,
    td {
        border-bottom: 1px solid #666;
        padding: 10px 0;
        vertical-align: middle;
    }
    tr {
        &:first-child {
            th,
            td {
                border-top: 1px solid #666;
            }
        }
    }
    th {
        text-align: left;
    }
    td {
        text-align: right;
    }
}
.table02 {
    width: 100%;
    background: #fff;
    th,
    td {
        border: 1px solid #ccc;
        padding: 10px;
    }
    tr {
    }
    th {
        text-align: left;
        background: #eee;
    }
    td {
    }
}

.flow02 {
    &__item {
        @include shadowBox01;
        padding: 60px 100px 70px;
        position: relative;
        @include media(sp) {
            padding: 30px 20px;
        }
        &:not(:last-child) {
            margin-bottom: 70px;
            @include media(sp) {
                margin-bottom: 50px;
            }
            &:after {
                content: "";
                width: 82px;
                height: 50px;
                background: url(/assets/img/icon_arrow04.svg) center / contain no-repeat;
                position: absolute;
                bottom: -60px;
                left: 50%;
                transform: translateX(-50%);
                @include media(sp) {
                    width: 41px;
                    height: 30px;
                    bottom: -40px;
                }
            }
        }
    }
}
.flow03 {
    &__row {
        display: flex;
        justify-content: space-between;
        min-height: 150px;
        margin-bottom: 10px;
        @include media(sp) {
            min-height: 125px;
            margin-bottom: 20px;
        }
    }
    &__cont {
        width: calc(100% - 62px - 20px);
        @include media(sp) {
            width: calc(100% - 36px - 10px);
        }
        &--line1 {
            @include media(pc) {
                padding-top: 0.9em;
            }
        }
    }
    &__step {
        width: 62px;
        font-size: 0;
        position: relative;
        @include media(sp) {
            width: 36px;
        }
        &:before {
            content: attr(data-text) "";
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            left: 0;
            line-height: 58px;
            width: 62px;
            height: 62px;
            border-radius: 100%;
            border: 1px solid $gray;
            text-align: center;
            font-size: 34px;
            @include media(sp) {
                line-height: 34px;
                width: 36px;
                height: 36px;
                font-size: 24px;
            }
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 80px;
            background: url(/assets/img/icon_arrow05.svg) center / contain no-repeat;
            top: 72px;
            left: 50%;
            transform: translateX(-50%);
            @include media(sp) {
                top: 46px;
            }
        }
    }
}

.link01 {
    display: inline-block;
    position: relative;
    padding: 0 2px 2px;
    &:hover {
    }
    &:after {
        content: "";
        height: 1px;
        width: 100%;
        display: block;
        background: #666;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
.link02 {
    display: inline-block;
    position: relative;
    margin: 0 1px;
    padding: 0 0px 2px;
    color: $site_color;
    &:hover {
    }
    &:after {
        content: "";
        height: 2px;
        width: 100%;
        display: block;
        background: $site_color;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
// @keyframes hover_line {
//     0% {
//         transform: translateX(0%);
//     }
//     45% {
//         opacity: 1;
//         transform: translateX(100%);
//     }
//     50% {
//         opacity: 0;
//         transform: translateX(100%);
//     }
//     51% {
//         opacity: 0;
//         transform: translateX(-120%);
//     }
//     55% {
//         opacity: 1;
//         transform: translateX(-120%);
//     }
//     100% {
//         transform: translateX(0%);
//     }
// }

.line01 {
    border-top: 1px solid #9f9f9f;
    &.mg-l {
        margin: 120px 0;
        @include media(sp) {
            margin: 45px 0;
        }
    }
}

//アコーディオン汎用設定
.js-acd {
    &__toggle {
        cursor: pointer;
    }
    &__cont {
        display: none;
    }
}
.acd01 {
    &__toggle {
        margin-top: 60px;
        padding-bottom: 25px;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #00a0e9;
        @include hoverEffect01;
        @include media(sp) {
            margin-top: 30px;
            padding-bottom: 20px;
        }
        &:before {
            //tate
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 25px;
            background: #00a0e9;
            left: 50%;
            bottom: 0;
            @include media(sp) {
                height: 20px;
            }
        }
        &:after {
            //yoko
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 120px;
            background: linear-gradient(to bottom, transparent 0%, #fff 80%);
            left: 0;
            bottom: 100px;
            z-index: 1;
            @include media(sp) {
                bottom: 65px;
            }
        }
    }
    &__cont {
    }
    &.is-open {
        .acd01__toggle {
            &:after {
                display: none;
            }
        }
    }
}

.map-box {
    position: relative;
    filter: saturate(0.6);
    &:before {
        content: "";
        display: block;
        position: relative;
        width: 100%;
        padding-top: 39%;
        @include media(sp) {
            // padding-top: 100%;
        }
    }
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
.scroll-box {
    background: #fff;
    overflow: auto;
    height: 268px;
    &__inner {
        padding: 25px;
    }
}

//debug
#scroll {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 100px;
    background: #fff;
    padding: 10px;
    width: 70px;
    text-align: right;
}
