@charset "utf-8";

/* --------------------------------------------------
TOPページスタイル
-------------------------------------------------- */
.home {
    &-content {
        position: relative;
        background: $site_color;
        // min-height: calc(100vh - 86px - 109px);
        padding-bottom: 50px;
    }
    .mv {
        text-align: center;
        font-family: $font_mincho;
        position: relative;
        @include margin-bottom(90px);
        &__copy {
            // font-size: 5.3rem;
            // color: $site_color;
            // letter-spacing: 0.2em;
            // line-height: 1.5;
            // margin-bottom: 20px;
            position: absolute;
            top: 8vw;
            left: 15vw;
            max-width: 39.4vw;
            @include media(sp) {
                top: 6vw;
                left: 14vw;
                max-width: 50vw;
            }
        }
        &__img {
            width: 100%;
            mix-blend-mode: darken;
        }
        &__txt {
            font-size: 2rem;
            letter-spacing: 0.1em;
            @include margin-bottom(40px);
            @include media(sp) {
                font-size: 18px;
            }
        }
    }
    .service {
        @include margin-bottom(140px);
        &__copy {
            text-align: center;
            font-family: $font_mincho;
            font-size: 3rem;
            letter-spacing: 0.15em;
            margin-bottom: 55px;
            @include media(sp) {
                font-size: 17px;
                margin-bottom: 30px;
                letter-spacing: 0.05em;
            }
        }
        &__img {
            @include margin-bottom(50px);
        }
        &__txt {
            text-align: center;
            font-family: $font_mincho;
            font-size: 28px;
            @include margin-bottom(80px);
            @include media(sp) {
                font-size: 16px;
            }
        }
    }
    .business {
        $delay: 1s;
        $dot_delay: 0.5s + 0.3s;
        $ttl_delay: 1.1s + 0.3s;
        $txt_delay: 1.4s + 0.3s;
        $label_delay: 1.7s + 0.3s;
        $svg_delay: 0.8s + 0.3s;
        $svg_delay_sp: 0.6s;
        $ttl_delay_sp: 0.9s;
        $txt_delay_sp: 1.2s;
        $label_delay_sp: 1.5s;
        margin-bottom: 50px;
        position: relative;
        @include media(sp) {
            max-width: 649px;
            margin: 0 auto 40px;
        }
        &-img {
            position: relative;
            &__base {
                display: block;
                margin: auto;
            }
            &__arrow {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                overflow: hidden;
                transition: height 2.8s;
                height: 0;
                @include media(sp) {
                    transition: height 2s ease-out;
                }
            }
        }
        &-header {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-family: $font_mincho;
            color: #fff;
            width: 100%;
            max-width: 794px;
            padding: 30px 0;
            @include media(tb) {
                padding: 20px 0;
            }
            @include media(sp) {
                padding: 5vw 3vw;
            }
            &__ttl {
                font-size: 48px;
                letter-spacing: 0.2em;
                margin-bottom: 15px;
                @include media(sp) {
                    @include fz_vw(20);
                }
            }
            &__txt {
                font-size: 18px;
                @include media(sp) {
                    text-align: left;
                    @include fz_vw(13);
                }
            }
        }
        &-item {
            max-width: 468px;
            width: 50%;
            position: absolute;
            @include media(sp) {
                max-width: none;
                width: calc(100% - 40px);
                margin-left: auto;
                margin-bottom: 11vw;
                position: relative;
            }
            &-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include media(sp) {
                    width: 90.4%;
                    height: 81%;
                    top: 19%;
                    left: auto;
                    right: 0;
                }
            }
            &__line {
                position: absolute;
                @include media(sp) {
                    left: -40px;
                    top: calc(8vw + 8px);
                    width: 41px;
                }
            }
            &__dot {
                position: absolute;
                width: 15px;
                z-index: 1;
                opacity: 0;
                transition: opacity 0.2s cubic-bezier(0.19, 0.47, 0.45, 0.94) $dot_delay;
                @include media(sp) {
                    top: 8vw;
                    left: -47.5px;
                    transition-delay: 0.5s;
                }
            }
            //左上
            &.website {
                @include media(pc) {
                    right: 50%;
                    top: 33%;
                    @include media(tb) {
                        top: 32%;
                    }
                    .business-item__line {
                        right: 0;
                        top: 15px;
                    }
                    .business-item__dot {
                        right: -8px;
                        top: 8px;
                    }
                }
            }
            //右上
            &.research {
                @include media(pc) {
                    left: 50%;
                    top: 35%;
                    .business-item__line {
                        left: 0;
                        top: 10px;
                    }
                    .business-item__dot {
                        left: -7px;
                        top: 3px;
                        transition-delay: $delay + $dot_delay;
                    }
                    .business-detail {
                        margin-left: auto;
                    }
                    .business-detail__ttl {
                        transition-delay: 0s, $delay + $ttl_delay;
                    }
                    .business-detail__txt {
                        transition-delay: $delay + $txt_delay;
                    }
                    .business-detail__label {
                        transition-delay: $delay + $label_delay;
                    }
                }
            }
            //左下
            &.system {
                @include media(pc) {
                    right: 50%;
                    top: 68%;
                    .business-item__line {
                        right: 0;
                        top: -106px;
                    }
                    .business-item__dot {
                        right: -8px;
                        top: -113px;
                    }
                }
            }
            //右下
            &.secretariat {
                @include media(pc) {
                    left: 50%;
                    top: 71.5%;
                    .business-item__line {
                        left: 0;
                        top: 62px;
                    }
                    .business-item__dot {
                        left: -7px;
                        top: 55px;
                        transition-delay: $delay + $dot_delay;
                    }
                    .business-detail {
                        margin-left: auto;
                    }
                    .business-detail__ttl {
                        transition-delay: 0s, $delay + $ttl_delay;
                    }
                    .business-detail__txt {
                        transition-delay: $delay + $txt_delay;
                    }
                    .business-detail__label {
                        transition-delay: $delay + $label_delay;
                    }
                }
            }
        }
        &-detail {
            // max-width: 410px;
            width: calc(100% - 58px);
            @include media(sp) {
                width: 100%;
            }
            &__ttl {
                background: url(/assets/img/top/business_ttl_off.svg) center / contain no-repeat;
                filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.2));
                position: relative;
                opacity: 0;
                transition: background 0.5s $easing01, opacity 0.8s cubic-bezier(0.19, 0.47, 0.45, 0.94) $ttl_delay;
                @include media(sp) {
                    transition-delay: 0s, $ttl_delay_sp;
                }
                &:before {
                    content: "";
                    display: block;
                    padding-top: 33.4%;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 48px;
                    height: 10px;
                    background: url(/assets/img/icon_arrow02.svg) center / contain no-repeat;
                    bottom: 36px;
                    right: 18px;
                    transition: transform 0.5s $easing01;
                    @include media(tb) {
                        width: 36px;
                        right: 13px;
                        bottom: 5vw;
                    }
                }
                &:hover {
                    opacity: 1;
                    background-image: url(/assets/img/top/business_ttl_on.svg);
                    .business-detail__ttl__inner {
                        color: #fff;
                    }
                    a {
                        opacity: 1;
                    }
                    &:after {
                        background-image: url(/assets/img/icon_arrow02_white.svg);
                        transform: translateX(8px);
                    }
                }
                &__inner {
                    display: flex;
                    align-items: center;
                    padding-left: 0.6em;
                    color: $site_color;
                    font-family: $font_mincho;
                    font-size: 28px;
                    letter-spacing: -0.05em;
                    width: 100%;
                    height: 87%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: color 0.5s $easing01;
                    @include media(tb) {
                        font-size: 20px;
                    }
                    @include media(sp) {
                        @include fz_vw(17);
                    }
                }
            }
            &__txt {
                font-size: 16px;
                font-feature-settings: "palt";
                @include top_fadeIn_anime($txt_delay);
                @include media(tb) {
                    font-size: 14px;
                }
                @include media(sp) {
                    @include fz_vw(11);
                    transition-delay: $txt_delay_sp;
                }
            }
            &__label {
                @include top_fadeIn_anime($label_delay);
                @include media(sp) {
                    transition-delay: $label_delay_sp;
                }
                li {
                    display: inline-block;
                    background: #e6e6e6;
                    font-size: 13px;
                    padding: 1px 12px;
                    @include media(tb) {
                        font-size: 11px;
                    }
                    @include media(sp) {
                        @include fz_vw(10);
                    }
                }
            }
        }
        .is-inview {
            &.business-img__arrow {
                height: 100%;
            }
            .svg-line {
                stroke-dashoffset: 0;
            }
            .business-item__dot,
            .business-detail__ttl,
            .business-detail__txt,
            .business-detail__label {
                opacity: 1;
                transform: translateY(0);
            }
        }
        svg .svg-line-website-1 {
            stroke-dashoffset: 110.1500015258789px;
            stroke-dasharray: 110.1500015258789px;
            transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            @include media(pc) {
                transition-delay: $svg_delay;
            }
        }
        svg .svg-line-research-1 {
            stroke-dashoffset: 107.14000701904297px;
            stroke-dasharray: 107.14000701904297px;
            transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            @include media(pc) {
                transition-delay: $svg_delay + $delay;
            }
        }
        svg .svg-line-system-1 {
            stroke-dashoffset: 228.08999633789062px;
            stroke-dasharray: 228.08999633789062px;
            transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            @include media(pc) {
                transition-delay: $svg_delay;
            }
        }
        svg .svg-line-secretariat-1 {
            stroke-dashoffset: 61.279998779296875px;
            stroke-dasharray: 61.279998779296875px;
            transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            @include media(pc) {
                transition-delay: $svg_delay + $delay;
            }
            @include media(sp) {
                transition-delay: $svg_delay_sp;
                transition-duration: 0.3s;
            }
        }
    }
    .partner {
        @include margin-bottom(110px);
    }
    .topics {
        background: #fff;
        padding: 40px 70px 50px;
        @include media(sp) {
            padding: 20px 10px;
        }
        .ttl01 {
            font-size: 3.5rem;
            text-align: left;
            margin-bottom: 10px;
            @include media(sp) {
                font-size: 2rem;
                margin-bottom: 0;
                text-align: center;
            }
        }
    }
}
