.l-header {
    width: 100%;
    position: fixed;
    height: $headerH;
    // background: url(/assets/img/bg01.png);
    z-index: 11;
    top: 0;
    // transition: height 0.5s $easing01;
    @include media(sp) {
        height: $headerH_sp;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/assets/img/bg01.png);
        transition: opacity 0.3s;
    }
    &.is-open{
        &:before {
            opacity: 0.8;
        }
    }
    &.menu-num2 {
        height: 146px;
    }
    &.menu-num3 {
        height: 175px;
    }
    &.menu-num4 {
        height: 204px;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4%;
        width: 100%;
        height: $headerH;
        position: relative;
        @include media(sp) {
            height: 100%;
            padding: 0 10px;
            z-index: 2;
        }
    }
    &__logo {
        z-index: 1;
        a {
            display: block;
            width: 190px;
            @include media(tb) {
                width: 160px;
            }
            @include media(sp) {
                width: 130px;
            }
        }
    }
    &__menu {
        @include media(sp) {
            display: none;
            background: url(/assets/img/bg01.png);
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            padding: $headerH_sp + 30 50px 50px;
            overflow: auto;
        }
    }
    &__menu-btn {
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 30px;
        @include media(pc) {
            display: none;
        }
        span {
            width: 100%;
            height: 1px;
            display: block;
            background: #666;
            transition: all 0.3s;
            position: absolute;
            &:nth-child(1) {
                top: 25%;
            }
            &:nth-child(2) {
                top: 50%;
            }
            &:nth-child(3) {
                top: 75%;
            }
        }
        .is-active & {
            span {
                &:nth-child(1) {
                    top: 50%;
                    transform: rotate(225deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    top: 50%;
                    transform: rotate(-225deg);
                }
            }
        }
    }
}
.l-nav {
    display: flex;
    font-family: $font_mincho;
    @include media(sp) {
        display: block;
    }
    &__item {
        position: relative;
        @include media(sp) {
            margin-bottom: 30px;
        }
        &:not(:last-child) {
            margin-right: 45px;
            @include media(tb) {
                margin-right: 20px;
            }
            @include media(sp) {
                margin-right: 0;
            }
        }
        &.parent {
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 13px;
                background: #666;
                left: 50%;
                top: 30px;
                opacity: 0;
                transition: opacity 0.5s;
            }
        }
        &.is-active {
            &:before {
                opacity: 1;
            }
        }
    }
    &__parent {
        position: relative;
        font-size: 18px;
        @include media(tb) {
            font-size: 14px;
        }
        @include media(sp) {
            font-size: 18px;
        }
        &[href="#"] {
            pointer-events: none;
        }
    }
    &__child {
        display: none;
        position: absolute;
        font-size: 16px;
        padding-top: 15px;
        white-space: nowrap;
        @include media(sp) {
            position: relative;
        }
        li {
            @include media(sp) {
                margin-bottom: 10px;
            }
        }
    }
    .sp-toggle {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 6px;
        vertical-align: text-bottom;
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-top: 1px solid #666;
            border-right: 1px solid #666;
            position: absolute;
            left: 6px;
            top: 4px;
            transform: rotate(135deg) skew(-1deg, -1deg);
        }
        @include media(pc) {
            display: none;
        }
    }
}
