/* --------------------------------------------------
下層ページ プライバシーポリシー policy 追加スタイル
-------------------------------------------------- */
.policy-area{
    p{
        @include fz_s;
        margin-bottom: 20px;
    }
}

