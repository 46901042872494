/* --------------------------------------------------
下層ページ サービス /business/ 追加スタイル
-------------------------------------------------- */
.business {
	.header {
		text-align: center;
		// margin-bottom: 60px;
		margin-bottom: 40px;
		@media #{$sp} {
			margin-bottom: 40px;
		}
		&__ttl {
			font-size: 60px;
			text-align: center;
			font-family: $font_mincho;
			color: $site_color;
			letter-spacing: 0.25em;
			transform: scaleX(0.95);
			@include media(sp) {
				font-size: 24px;
				letter-spacing: 0.1em;
			}
		}
		&__img {
			@include margin-bottom(30px);
		}
	}
	.flow {
		margin: 0 auto 120px;
		@media #{$sp} {
			margin-bottom: 60px;
		}
		&__top {
			text-align: center;
			@include margin-bottom(100px);
		}
		&__ttl {
			font-size: 16px;
			letter-spacing: 0.1em;
			margin-bottom: 10px;
		}
		&__img {
			margin-bottom: 10px;
		}
		&__copy {
			font-size: 20px;
			@media #{$sp} {
				font-size: 18px;
			}
		}
		&__txt01 {
			margin-bottom: 20px;
			font-feature-settings: "palt";
			@media #{$sp} {
				letter-spacing: normal;
			}
		}
		&__txt02 {
			font-size: 22px;
			color: $site_color;
			margin-bottom: 15px;
			@media #{$sp} {
				font-size: 18px;
			}
		}
	}
	.support {
		@include margin-bottom(120px);
		&__ttl {
			font-size: 24px;
			font-family: $font_mincho;
			text-align: center;
			position: relative;
			max-width: 748px;
			margin: 0 auto 40px;
			@media #{$sp} {
				font-size: 20px;
			}
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: calc(100% - 30px);
				height: calc(100% - 30px);
				@include media(sp) {
					width: calc(100% - 15px);
					height: calc(100% - 15px);
				}
			}
			&:before {
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				top: 0;
				right: 0;
			}
			&:after {
				border-left: 1px solid #000;
				bottom: 0;
				left: 0;
			}
			&__inner {
				width: 100%;
				height: 100%;
				display: block;
				padding: 25px 0;
				&:after {
					content: "";
					display: block;
					position: absolute;
					width: calc(100% - 30px);
					height: 16px;
					background: url(/assets/img/balloon_bottom.svg) 0 0 / 100% no-repeat;
					top: 100%;
					left: 0;
					@include media(sp) {
						width: calc(100% - 15px);
						background-image: url(/assets/img/balloon_bottom_sp.svg);
					}
				}
			}
		}
	}
	.service {
		@include margin-bottom(120px);
		&__box {
			@include shadowBox01;
			padding: 70px 70px 80px;
			@include media(sp) {
				padding: 30px 20px;
			}
		}
	}
	.example {
		@include margin-bottom(160px);
		&__box {
			@include shadowBox01;
			padding: 55px;
			@media #{$sp} {
				padding: 30px;
			}
		}
	}
	.feature {
		@include margin-bottom(130px);
	}
}
.system {
	.header {
		&__ttl {
			letter-spacing: 0.1em;
		}
		&__img {
			max-width: 548px;
		}
	}
	.price {
		@include margin-bottom(90px);
		&__box {
			display: flex;
			justify-content: space-between;
			@media #{$sp} {
				display: block;
			}
		}
		&__txt {
			width: 40%;
			@media #{$sp} {
				width: 100%;
				margin-bottom: 15px;
			}
		}
		&__table {
			width: 54%;
			padding-top: 0.5em;
			@media #{$sp} {
				width: 100%;
				padding-top: 0;
			}
		}
		&__notes {
			font-size: 13px;
			text-align: right;
			margin-top: 5px;
		}
	}
}
.research {
	.header {
		&__img {
			max-width: 604px;
		}
	}
	.phase {
		.ttl04 {
			margin-bottom: 10px;
		}
		p {
			font-size: 16px;
			& + .ttl04 {
				margin-top: 40px;
			}
		}
	}
	.special {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 30px 0 60px;
		@include media(sp) {
			display: block;
		}
		&__cont {
			width: 58%;
			margin-bottom: 15px;
			@include media(sp) {
				width: 100%;
			}
		}
		&__ttl {
			font-weight: $bold;
			margin-bottom: 15px;
		}
		&__balloon {
			background: $site_color;
			color: #fff;
			text-align: center;
			font-size: 18px !important;
			margin-bottom: 20px;
			padding: 5px;
			position: relative;
			@include media(sp) {
				font-size: 16px !important;
			}
			&:before {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12px 12px 0;
				border-color: transparent $site_color transparent transparent;
				position: absolute;
				top: 100%;
				left: 35px;
			}
		}
		&__txt {
			font-size: 16px;
		}
		&__img {
			width: 40%;
			margin-bottom: 15px;
			@include media(sp) {
				width: 100%;
			}
			img {
				@include media(pc) {
					transform: translateX(15px);
				}
			}
		}
		&__btn {
			width: 100%;
		}
	}
	.flow02__item:last-child {
		.ttl02__sub {
			background: #d9d9d9;
		}
	}
	.example {
		&__txt {
			font-size: 16px;
			font-feature-settings: "palt";
			line-height: 2;
			max-width: 750px;
			margin: 0 auto;
		}
	}
}
.website {
	.header {
		&__img {
			max-width: 574px;
		}
	}
	.plan {
		&__box {
			@include shadowBox01;
			padding: 80px 50px 60px;
			@include media(sp) {
				padding: 30px 20px;
			}
		}
	}
	.example {
		&__box {
			padding: 40px 40px 55px;
			@include media(sp) {
				padding: 20px;
			}
			.list05__item a:hover {
				background: #00008c;
				color: #fff;
				opacity: 1;
			}
		}
	}
	.feature {
		.box02 {
			margin-bottom: 25px;
		}
	}
}
.secretariat {
	.header {
		&__img {
			max-width: 507px;
		}
	}
}

// /system/の下層
.system-sub {
	.header {
		text-align: center;
		@include margin-bottom(120px);
		&__img {
			@include margin-top(60px);
			max-width: 100%;
		}
	}
}
.medication_support {
	p + .ttl02 {
		@include margin-top(50px);
	}
}
.share {
	.box06 {
		padding: 60px 50px 100px;
		@include media(sp) {
			padding: 30px 20px;
		}
	}
	.ttl06 {
		letter-spacing: 0.1em;
	}
	.btn01 {
		a {
			@include media(pc) {
				min-width: 478px;
			}
		}
	}
}
.edc {
	.box06 {
		padding: 50px 50px 60px;
		@include media(sp) {
			padding: 20px;
		}
	}
	.ttl06 {
		letter-spacing: 0.1em;
	}
	.btn01 {
		a {
			@include media(pc) {
				min-width: 478px;
			}
		}
	}
}
.epro {
	.box06 {
		padding: 50px 50px 60px;
		@include media(sp) {
			padding: 20px;
		}
	}
}
