@charset "utf-8";
// ===================================================================
// 設定用
// ===================================================================

// webfont
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&family=Shippori+Mincho:wght@500&display=swap");

// フォント
$font_gothic: "Noto Sans JP", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "メイリオ", Meiryo,
    "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif;
$font_mincho: "Shippori Mincho", "ヒラギノ明朝", "Hiragino Mincho", "游明朝", YuMincho, "Times New Roman", "HG明朝E",
    "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

// ベースフォント
$base_font: $font_gothic;

//サイトカラー
$site_color: #00008c;
$sub_color: #aea397;
$gray: #251714;

//テキストベースカラー
$font_color: #000;

//リンクベースカラー
$link_color: #000;

//太字のウェイト
$bold: 500;

//ベンダープレフィックス
$prefixList: -webkit-, -moz-, -ms-, null;

//ヘッダー高さ
$headerH: 110px;
$headerH_sp: 54px;

$easing01: cubic-bezier(0.65, 0.26, 0, 0.67);
$easing02: cubic-bezier(0.46, 0.03, 0.52, 0.96);

// レスポンシブサイズ
$pc: "screen and (min-width: 768px)";
$pc_small: "screen and (max-width: 1200px)";
$tb: "screen and (max-width: 1023px)";
$sp: "screen and (max-width: 767px)";
$sp_small: "screen and (max-width: 374px)";

$breakpoints: (
    "pc": "screen and (min-width: 768px)",
    "pc_small": "screen and (max-width: 1200px)",
    "tb": "screen and (max-width: 1023px)",
    "sp": "screen and (max-width: 767px)",
    "sp_small": "screen and (max-width: 374px)",
);
@mixin media($breakpoint: sp) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}
