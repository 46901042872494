.l-modal {
    display: none;
    background: #fff;
    max-height: 96vh;
    position: fixed;
    z-index: -1;
    visibility: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media #{$sp} {
        width: 90vw;
        min-width: 0;
    }
    &--img {
        @include media(pc) {
            width: auto;
        }
        img {
            max-height: 88vh;
        }
    }
    &.is-show {
        z-index: 100;
        visibility: visible;
    }
    &-bg,
    &-bg-disable {
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(0, 0, 0, 0.7);
        top: 0;
        left: 0;
        z-index: 100;
    }
    &-close {
        cursor: pointer;
        padding: 0;
        display: block;
        width: 36px;
        height: 36px;
        position: absolute;
        right: -100px;
        top: -40px;
        z-index: 1;
        background: rgba(0, 0, 0, 0.7);
        @include media(sp) {
            right: 0;
            top: -45px;
        }
        &::before,
        &::after {
            content: "";
            display: block;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: left top;
            transform: rotate(-45deg) translate(-50%, -50%);
        }
        &::before {
            width: 1px;
        }
        &::after {
            height: 1px;
        }
        @media #{$pc} {
            &::before {
                height: 28px;
            }
            &::after {
                width: 28px;
            }
        }
        @media #{$sp} {
            &::before {
                height: 24px;
            }
            &::after {
                width: 24px;
            }
        }
    }
    &-content {
        overflow: auto;
        height: 100%;
    }
}

.modal-img {
    display: block;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2) url(/assets/img/icon_zoom.svg) right 10px bottom 15px / 22px no-repeat;
        opacity: 0;
        transition: opacity 0.3s;
    }
    &:hover {
        cursor: zoom-in;
        opacity: 1;
        &:before {
            opacity: 1;
        }
    }
    img:not(.modal-img--nostyle) {
        border: 1px solid #bbb;
        @include shadow01;
    }
}
