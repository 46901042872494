@charset "utf-8";
// ===================================================================
// リセットCSS
// ===================================================================


/*! Reset CSS */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
a, b, i, input, textarea, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	font-weight: 300;
	// font-family: $base_font;
	vertical-align: baseline;
	background: transparent;
	// color:$font_color;
	box-sizing: border-box;
}
body {
	line-height: 1.7;
	color: $font_color;
}
span {
	font-size:inherit;
	font-weight:inherit;
	color:inherit;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
ul {
	list-style: none;
}
a {
	color: $link_color;
	outline: none;
	text-decoration: none;
}
a:hover {
	// text-decoration: underline;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}
input, select {
	vertical-align: middle;
	appearance: none;
	border-radius: 0;
}
img {
	vertical-align:bottom;
	max-width:100%;
	height:auto;
}
sup{
	vertical-align: baseline;
	position: relative;
	top: -1ex;
}
button{
	outline:none;
}




