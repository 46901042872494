@charset "utf-8";
// ===================================================================
// main
// ===================================================================

html {
    font-size: 62.5%;
    font-family: $base_font;
    &.no-scroll {
        @media #{$sp} {
            position: fixed;
            z-index: -1;
            width: 100%;
            height: 100%;
            // overflow: hidden;
        }
        body {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: fixed;
        }
    }
}
body {
    font-size: 1.8rem;
    background: #f5f2f4 url(/assets/img/bg01.png);
    line-break: strict;
    @media #{$sp} {
        font-size: 1.6rem;
    }
}
a {
    @media #{$tb} {
        -webkit-tap-highlight-color: transparent;
        &:hover {
            text-decoration: none;
        }
    }
    @media #{$pc} {
        transition: all 0.3s;
        &:hover,
        img:hover {
            opacity: 0.7;
        }
    }
}

#wrap {
    width: 100%;
    overflow: hidden;
}
.l-container {
    @include contentInner;
    &--m {
        max-width: 850px + 32px * 2;
    }
    &--s {
        max-width: 750px + 32px * 2;
    }
}
.l-main {
    padding-top: $headerH + 50;
    // min-height: calc(100vh - $headerH - 50px);
    @media #{$sp} {
        padding-top: $headerH_sp + 30;
        // min-height: calc(100vh - $headerH_sp - 40px);
    }
    // .home &{ //MV固定時
    //     padding-top: calc(6.25vw + 56.25vw);
    //     position: relative;
    //     @media #{$sp}{
    //         padding-top: calc(14.4vw + 132.8vw);
    //     }
    // }
}
.l-breadcrumb {
    font-size: 15px;
    margin-top: 110px;
    font-family: $font_mincho;
    @media #{$sp} {
        font-size: 10px;
        margin-top: 60px;
    }
    &__item {
        display: inline-block;
        &:not(:last-child) {
            position: relative;
            padding-right: 40px;
            @include media(sp) {
                padding-right: 20px;
            }
            &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid #666;
                border-right: 1px solid #666;
                position: absolute;
                right: 16px;
                top: 54%;
                transform: translateY(-50%) rotate(45deg);
                @include media(sp) {
                    width: 5px;
                    height: 5px;
                    right: 8px;
                }
            }
        }
    }
}
.l-anchor {
    padding-top: $headerH !important;
    margin-top: -$headerH !important;
}
.l-sec {
    &--mb80 {
        margin-bottom: 80px;
        @include media(sp) {
            margin-bottom: 40px;
        }
    }
    &--mb100 {
        margin-bottom: 100px;
        @include media(sp) {
            margin-bottom: 50px;
        }
    }
    &--mb120 {
        margin-bottom: 120px;
        @include media(sp) {
            margin-bottom: 60px;
        }
    }
    &--mb160 {
        margin-bottom: 160px;
        @include media(sp) {
            margin-bottom: 80px;
        }
    }
}
.l-block {
    margin-bottom: 60px;
    @media #{$sp} {
        margin-bottom: 30px;
    }
}
