/* --------------------------------------------------
下層ページ リクルート /recruit/ 追加スタイル
-------------------------------------------------- */
.recruit {
}
.top {
    .lead {
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        &__txt {
            font-size: 22px;
            letter-spacing: 0.1em;
            @include media(sp) {
                font-size: 20px;
            }
        }
        .indent {
            @include fz_s;
        }
    }
    .message {
        &__copy {
            color: $site_color;
            font-size: 24px;
            font-feature-settings: "palt";
            @include media(sp) {
                font-size: 20px;
            }
        }
    }
    .ttl08{
        @include media(pc) {
            font-size: 30px;
        }

    }
    .flow{
        @include margin-top(60px);
    }
}
